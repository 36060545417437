@import '@vg-constellation/angular-13/styles/c11n';
@import '@vg-constellation/styles/breakpoints';
@import '@vg-constellation/styles/components/table';
@import '@vg-constellation/styles/icons';
@import '@vg-constellation/styles/components/link';


.c11n-button,
.c11n-dropdown button,
.link {
  cursor: pointer;
}

.link {
  display: inline-block;
}

.c11n-dropdown {
  .c11n-dropdown {
    &__container {
      width: 26rem;
      @include media-breakpoint-down(lg) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

/////UTILITY CLASSES/////
/// 
.gap-1point5{
  gap: 1.5em !important;
}
